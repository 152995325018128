<template>
  <div class="carousel-item w-full">
    <span class="lqd-carousel-handle"></span>
    <div
        class="lqd-testi relative lqd-testi-style-18 pt-2/5em pb-1/5em lqd-testi-shadow-sm2 lqd-testi-details-sm lqd-testi-quote-18 lqd-testi-avatar-68 text-center module-line-1">
      <div class="icon-text-nightblue flex justify-center mb-4">
        <ul class="lqd-star-rating lqd-star-rating-shaped lqd-star-rating-outline">
          <li v-for="i in stars" :key="i">
            <img src="@/assets/img/icons/star.svg" alt="Star" class="star">
          </li>
        </ul>
      </div>
      <div class="lqd-testi-quote mb-1/5em px-1em">
        <blockquote>
          <p>
              <span style="font-weight: 500;">
                {{ body }}
              </span>
          </p>
        </blockquote>
      </div>
      <div class="lqd-testi-info flex flex-col flex-wrap justify-between">
        <div class="lqd-testi-details flex items-center justify-center">
          <div class="lqd-testi-np text-start pl-1/5em">
            <h3 class="uppercase tracking-0/1em font-bold">{{ name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingView",
  props: {
    name: {},
    body: {},
    stars: {}
  }
}
</script>