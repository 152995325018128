<template>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 text-center">
        <div class="mt-4">
        </div>
      <h1 class="pb-30 mt-4 text-primary">404 - nenalezeno</h1>
        <h4 class="fw-bold">požadovaná stránka nebyla nalezena. Můžete se vrátit <router-link to="/">domů</router-link></h4>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'NotFound',
}
</script>

<style>

</style>
