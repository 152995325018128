import GroupScreen from "@/views/GroupScreen.vue";
import HomeScreen from "@/views/HomeScreen.vue";
import NotFound from "@/views/NotFound.vue";

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeScreen,
        meta: {
            title: 'Domů',
        }
    },
    {
        path: '/skupina/:route',
        name: 'Group',
        component: GroupScreen,
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404-not-found',
        component: NotFound,
        meta: {
            title: '404',
        }
    },
]
