import L17 from "@/assets/img/icons/groups/L17.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Fanda from "@/assets/img/groups/b/fanda.webp";
import Bile from "@/assets/img/groups/b/bile.webp";
import BilePopis from "@/assets/img/groups/b/bile_popis.webp";
import Cervene from "@/assets/img/groups/b/cervene.webp";
import Hnede from "@/assets/img/groups/b/hnede.webp";

export default {
    title: "Osobní Auto L17",
    subtitle: "od 17 let",
    image: L17,
    headingImage: Fanda,
    route: 'l17',
    pageTitle: "Skupina B (L17)",
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 18 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "17 let"
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "do 3 500kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 34 hodin praxe"
        }
    ],
    information: [
        {
            text: "Žadatel v režimu L17 může získat řidičské oprávnění skupiny B už od věku 17 let.",
        },
        {
            text: "Žadatel musí absolvovat klasický výcvik v autoškole včetně složení obvyklých závěrečných zkoušek.",
        },
        {
            text: "Řidič L17 smí od 17 let řídit osobní automobil pouze pod dohledem konkrétního zkušeného řidiče (mentor).",
        },
        {
            text: "Mentor musí splňovat kvalifikační předpoklady, při jízdě sedět na místě spolujezdce a nebýt pod vlivem alkoholu.",
        },
        {
            text: "Režim L17 se zapíše do Evidenční karty řidiče mladého řidiče i mentora.",
        },
        {
            text: "Mentorem může být pouze řidič, který je držitelem řidičského oprávnění skupiny B po dobu alespoň 10 let (zkušený řidič), kterému nebyl v posledních 5 letech udělen zákaz řízení a který má v bodovém hodnocení řidičů 0 bodů. Mentorem je nejčastěji rodič nebo příbuzný mladého řidiče, není to však podmínkou.",
        },
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 17 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Fanda,
            alt: ""
        },
        {
            image: Bile,
            alt: ""
        },
        {
            image: BilePopis,
            alt: ""
        },
        {
            image: Cervene,
            alt: ""
        },
        {
            image: Hnede,
            alt: ""
        },
    ],
}