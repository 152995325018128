import AM from "@/assets/img/icons/groups/AM.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Speed from "@/assets/img/icons/speed.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import Clock from "@/assets/img/icons/clock.svg";

import BilySkutr from "@/assets/img/groups/am_a1/bily_skutr.webp";
import CervenySkutr from "@/assets/img/groups/am_a1/cerveny_skutr.webp";
import Skutry from "@/assets/img/groups/am_a1/skutry.webp";

import Ride1 from "@/assets/img/groups/a_global/1.webp";
import Ride2 from "@/assets/img/groups/a_global/2.webp";
import Ride3 from "@/assets/img/groups/a_global/3.webp";
import Ride4 from "@/assets/img/groups/a_global/4.webp";
import Ride5 from "@/assets/img/groups/a_global/5.webp";
import Ride6 from "@/assets/img/groups/a_global/6.webp";
import Ride7 from "@/assets/img/groups/a_global/7.webp";
import Ride8 from "@/assets/img/groups/a_global/8.webp";
import Moto1 from "@/assets/img/groups/a_global/moto.webp";
import Moto2 from "@/assets/img/groups/a_global/skupina.webp";

export default {
    title: "Motocykl AM",
    pageTitle: "Skupina AM",
    subtitle: "od 15 let",
    headingImage: BilySkutr,
    image: AM,
    route: 'am',
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 14 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "od 15 let"
        },
        {
            icon: Speed,
            title: "Maximální konstrukční rychlost",
            body: "45km/h"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "9 hodin teorie a 18 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 15 let'
        },
        {
            icon: ID,
            text: 'Do 18 let je potřeba souhlas zákonného zástupce'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: BilySkutr,
            alt: ""
        },
        {
            image: CervenySkutr,
            alt: ""
        },
        {
            image: Skutry,
            alt: ""
        },
        {
            image: Ride1,
            alt: ""
        },
        {
            image: Ride2,
            alt: ""
        },
        {
            image: Ride3,
            alt: ""
        },
        {
            image: Ride4,
            alt: ""
        },
        {
            image: Ride5,
            alt: ""
        },
        {
            image: Ride6,
            alt: ""
        },
        {
            image: Ride7,
            alt: ""
        },
        {
            image: Ride8,
            alt: ""
        },
        {
            image: Moto1,
            alt: ""
        },
        {
            image: Moto2,
            alt: ""
        },
    ],
}