import A1 from "@/assets/img/icons/groups/A1.svg";
import Power from "@/assets/img/icons/power.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Bila125 from "@/assets/img/groups/am_a1/bila_125.webp";
import CB125F from "@/assets/img/groups/am_a1/cb125f.webp";
import Cerna from "@/assets/img/groups/am_a1/cerna.webp";
import Cerna125 from "@/assets/img/groups/am_a1/cerna_125.webp";
import Stribrna125 from "@/assets/img/groups/am_a1/stribrna_125.webp";
import KTM from "@/assets/img/groups/am_a1/ktm.webp";

import Ride1 from "@/assets/img/groups/a_global/1.webp";
import Ride2 from "@/assets/img/groups/a_global/2.webp";
import Ride3 from "@/assets/img/groups/a_global/3.webp";
import Ride4 from "@/assets/img/groups/a_global/4.webp";
import Ride5 from "@/assets/img/groups/a_global/5.webp";
import Ride6 from "@/assets/img/groups/a_global/6.webp";
import Ride7 from "@/assets/img/groups/a_global/7.webp";
import Ride8 from "@/assets/img/groups/a_global/8.webp";
import Moto1 from "@/assets/img/groups/a_global/moto.webp";
import Moto2 from "@/assets/img/groups/a_global/skupina.webp";

export default {
    title: "Motocykl A1",
    pageTitle: "Skupina A1",
    headingImage: Bila125,
    subtitle: "od 16 let",
    image: A1,
    route: 'a1',
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 14 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "od 16 let"
        },
        {
            icon: Power,
            title: "Maximální výkon",
            body: "11kw"
        },
        {
            icon: Power,
            title: "Maximální objem",
            body: "125cc"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "9 hodin teorie a 18 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 16 let'
        },
        {
            icon: ID,
            text: 'Do 18 let je potřeba souhlas zákonného zástupce'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: KTM,
            alt: ""
        },
        {
            image: Bila125,
            alt: ""
        },
        {
            image: CB125F,
            alt: ""
        },
        {
            image: Cerna,
            alt: ""
        },
        {
            image: Cerna125,
            alt: ""
        },
        {
            image: Stribrna125,
            alt: ""
        },
        {
            image: Ride1,
            alt: ""
        },
        {
            image: Ride2,
            alt: ""
        },
        {
            image: Ride3,
            alt: ""
        },
        {
            image: Ride4,
            alt: ""
        },
        {
            image: Ride5,
            alt: ""
        },
        {
            image: Ride6,
            alt: ""
        },
        {
            image: Ride7,
            alt: ""
        },
        {
            image: Ride8,
            alt: ""
        },
        {
            image: Moto1,
            alt: ""
        },
        {
            image: Moto2,
            alt: ""
        },
    ],
}