<template>
  <div id="lqd-contents-wrap">

    <div class="image-background" :style="{backgroundImage: `url(${group.headingImage ? group.headingImage : bgImage})` }">
      <div class="dark-bg">
        <h1 class="heading">{{ group.pageTitle }}</h1>
      </div>
    </div>

    <section class="lqd-section icon-box pt-60 bg-center bg-no-repeat pl-30 pr-30" v-if="group.heading">
      <div class="container-fluid">
        <div class="row justify-center">
          <div class="col col-12">
            <div class="module-container flex flex-wrap justify-between mx-auto w-950 md:w-full">
              <div class="lqd-iconbox-scale w-45percent mb-65" :key="index" v-for="(heading, index) in group.heading">
                <div class="iconbox flex flex-grow-1 relative iconbox-side iconbox-square text-start">
                  <div class="iconbox-icon-wrap">
                    <div
                        class="iconbox-icon-container inline-flex relative z-1 w-80 h-80 text-30 rounded-20 items-center justify-center"
                        style="background-color: rgba(232,232,244,0.75)">
                      <img :src="heading.icon" style="width: 50px" :alt="heading.title"/>
                    </div>
                  </div>
                  <div class="contents">
                    <h3 class="lqd-iconbox-heading text-20 mb-0/85em">
                      <span>{{ heading.title }}</span>
                    </h3>
                    <p v-html="heading.body"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #FAF9FE 100%); padding-top: 50px;"
             v-if="group.training">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10" style="margin-bottom: 100px; text-align: left" :key="index" v-for="(extension, index) in group.training">
                <h5 style="width: 100%;text-align: center;margin-top: 20px">{{extension.subtitle}}</h5>
                <h3 style="width: 100%;text-align: center;margin-top: 20px">{{extension.title}}</h3>
                <p style="margin-top: 20px">{{extension.text}}</p>
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Rozsah výcviku</th>
                    <th scope="col">Cena výcviku</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ extension.length }}</td>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="lqd-section pb-70 pt-70 bg-transparent transition-all z-1 relative" v-if="group.conditions">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Podmínky pro přijetí</h2>
          </div>
          <div class="relative w-full">
            <div class="w-100percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full animation-element">
              <div class="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10" :key="index"
                   v-for="(condition, index) in group.conditions">
                <div class="iconbox-icon-wrap mr-15 text-12 w-20 h-20 flex items-center justify-center">
                  <div class="iconbox-icon-container inline-flex relative z-1">
                    <img :src="condition.icon" alt="icon" style="width: 25px;">
                  </div>
                </div>
                <div class="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black" v-html="condition.text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="lqd-section pb-130 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #fff 100%);" v-if="group.information">
      <div class="container">
        <div class="w-full flex flex-col items-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Informace k výcviku</h2>
          </div>
          <div class="relative w-full">
            <div class="w-100percent flex flex-col gap-12 my-10 p-10 transition-bg sm:w-full animation-element">
              <div class="iconbox flex flex-grow-1 relative iconbox-circle text-left mb-10" :key="index"
                   v-for="(information, index) in group.information">
                <div class="iconbox-icon-wrap mr-15 text-12 w-20 h-20 flex items-center justify-center">
                  <div class="iconbox-icon-container inline-flex relative z-1">
                    <i class="fa-solid fa-circle-info"></i>
                  </div>
                </div>
                <div class="lqd-iconbox-heading text-15 font-medium -mt-0/1em text-black">{{ information.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #FAF9FE 100%); padding-top: 50px;"
             v-if="group.extensions">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Rozšíření</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Ze skupiny</th>
                    <th scope="col">Rozsah výcviku</th>
                    <th scope="col">Věk</th>
                    <th scope="col">Cena rozšíření</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :key="index" v-for="(extension, index) in group.extensions">
                    <th scope="row">
                      <img :src="extension.fromGroup" alt="group" style="width: 40px"/></th>
                    <td>{{ extension.length }}</td>
                    <td>{{ extension.age }}</td>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="lqd-section pb-70 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #FAF9FE 100%); padding-top: 50px;"
             v-if="group.hours">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Cenník</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col">Skupina</th>
                    <th scope="col">Hodinová sazba</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :key="index" v-for="(extension, index) in group.hours">
                    <th scope="row">
                      <img :src="extension.fromGroup" alt="group" style="width: 40px"/></th>
                    <td>{{ extension.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="lqd-section pb-130 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #FAF9FE 100%); padding-top: 50px;"
             v-if="group.gallery">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">S čím budete jezdit</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <GalleryView :images="group.gallery"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import GalleryView from "@/components/Home/GalleryView.vue";

export default {
  name: 'GroupScreen',
  components: {GalleryView},
  computed: {
    group() {
      return this.$store.getters.getGroupByRoute(this.$route.params.route);
    }
  },
  mounted() {
    document.title = this.group.title + ' | Autoškola Fišer';
  },
  data() {
    return {
      bgImage: require('../assets/img/bg.webp')
    }
  }
}

</script>

<style>
.image-background {
  width: 100vw;
  height: 250px;
  background-size: cover;
  background-position: center center;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.64);
  width: 100vw;
  height: 250px;
}

.heading {
  font-size: 60px;
  color: white;
  text-align: center;
  line-height: 250px
}

@media (max-width: 767px) {
  .icon-box .lqd-iconbox-scale {
    margin-bottom: 35px;
    width: 100%;

    &:hover {
      transform: scale(1);
    }
  }
}

.icon-box .lqd-iconbox-scale {
  @media (max-width: 767px) {
    margin-bottom: 35px;
    width: 100%;

  }

  &:hover {
    transform: scale(1);
  }

}
</style>