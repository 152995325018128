import {createStore} from 'vuex'


import BG from "../../public/assets/img/bg.webp";
import AM from "@/store/groups/am";
import A1 from "@/store/groups/a1";
import A2 from "@/store/groups/a2";
import A from "@/store/groups/a";
import B from "@/store/groups/b";
import BE from "@/store/groups/be";
import B96 from "@/store/groups/b96";
import BAUTO from "@/store/groups/bauto";
import L17 from "@/store/groups/l17";
import C from "@/store/groups/c";
import CE from "@/store/groups/ce";
import T from "@/store/groups/t";
import D from "@/store/groups/d";
import DE from "@/store/groups/de";
import Training from "@/store/groups/training";
import KJ from "@/store/groups/kj";
import Car from "@/assets/img/groups/global/moto_auto.webp";
import Ride1 from "@/assets/img/groups/a_global/1.webp";
import Ride3 from "@/assets/img/groups/a_global/7.webp";


export default createStore({
    state: {
        gallery: [
            {
                image: BG,
                alt: ""
            },
            {
                image: Car,
                alt: ""
            },
            {
                image: Ride1,
                alt: ""
            },
            {
                image: Ride3,
                alt: ""
            },
        ],
        ratings: [
            {
                name: "Johan Široký",
                body: "Chtěl bych poděkovat Fandovi Fišerovi za jeho neskutečnou trpělivost při jízdách, a že mě skvěle připravil k závěrečným zkouškám. Když autoškolu, tak jedině od Fišerů!",
                stars: 5
            },
            {
                name: "Adéla Pecharová",
                body: "Chtěla bych hrozně poděkovat Autoškole Fišer za skvělý přístup pana Fišera staršího při přípravě na testy, pana Jakoubka za skvělé jízdy a Martinovi Fišerovi za super přístup u zkoušek. Vám všem patří mé velké díky. :))",
                stars: 5
            },
            {
                name: "Denisa Záveská",
                body: "Jsem naprosto spokojená v autoškole jsem prospěla 🙂 teorie u pana Fišera staršího byla skvělá snaží se vás naučit řádně a pečlivě co nejvíce a můj učitel kterého jsem měla já Marťase tak tam taky žádný problém nebyl je hodný, trpělivý a též se snaží vše vysvětlit řádně a naučit za mě naprostá spokojenost 😍😍 děkuji",
                stars: 5
            },
            {
                name: "Lukáš Petráček",
                body: "Nejlepší autoškola! Moc doporučuji pana Fišera. Dělal jsem si řidičák na velkou motorku (A). Pan Fišer vše vysvětlí, ukáže a naučí, a to vše naprosto pohodovou formou. Vždy jsem se na jízdy moc těšil a na závěrečné zkoušky jsem byl skvěle připraven. Přihlášku jsem si podával koncem května a za po měsíci jsem měl řidičák úspěšně hotový. Tímto chci ještě jednou moc poděkovat za super zkušenost a všem moc doporučuji! :-)",
                stars: 5
            },
        ],
        groups: [AM, A1, A2, A, B, L17, BAUTO, BE, B96, C, CE, T, D, DE, Training, KJ]
    },
    getters: {
        getGroupByRoute: (state) => (route) => {
            return state.groups.find(item => item.route === route)
        }
    }
})