<template>
  <a class="col col-6 col-md-2 px-10 my-5" :href="'/skupina/' + route">
    <div
        class="lqd-iconbox-scale flex flex-wrap justify-center m-0 py-25 px-15 rounded-10 bg-lightgray text-center transition-all relative hover:scale-1/1 hover:inner-opacity-100 module-iconbox">
                        <span class="absolute w-full h-full top-0 left-0 rounded-12 opacity-0 inner-opacity-0 transition-opacity bg-blue"></span>
      <div class="iconbox flex-grow-1 relative flex-col iconbox-default">
        <div class="iconbox-icon-wrap">
          <div class="iconbox-icon-container inline-flex w-50 text-50 mb-20">
            <img :src="image"
                 :alt="title">
          </div>
        </div>
        <h3 class="lqd-iconbox-heading text-15 text-text">{{ title }}</h3>
        <h4 class="text-text text-12 m-0">{{ subtitle }}</h4>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  name: "GroupView",
  props: {
    title: {},
    subtitle: {},
    image: {},
    route: {},
  }
}
</script>