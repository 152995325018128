<template>
  <div class="bg-white" id="wrap">
    <PageHeader />

    <main class="content" id="lqd-site-content">
      <router-view/>
    </main>

    <div class="lqd-top-scrol-ind fixed top-0 left-0 z-10 w-full pointer-events-none bg-white"
         data-lqd-scroll-indicator="true"
         data-scrl-indc-options='{"scale":  true, "dir": "x" ,  "end": "bottom bottom" ,  "origin": "left"}'>
				<span class="lqd-scrl-indc-inner block lqd-overlay flex">
					<span class="lqd-scrl-indc-line block lqd-overlay flex">
						<span class="lqd-scrl-indc-el block lqd-overlay flex"
                  style="background-image: linear-gradient(90deg, rgb(233, 164, 194) 0%, rgb(164, 104, 252) 50%, #74c6e5 100%);"></span>
					</span>
				</span>
    </div>

    <PageFooter />
  </div>
</template>
<script>

// @ is an alias to /src
// Bootstrap JS
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
  }
}
</script>

<style>

/*!* Bootstrap CSS *!*/
@import "./assets/scss/main.css";


</style>
