import BAUTO from "@/assets/img/icons/groups/Bauto.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import Shift from "@/assets/img/icons/shift.svg";

import BilePopis from "@/assets/img/groups/b/bile_popis.webp";

export default {
    title: "B Automat",
    subtitle: "od 17 let",
    image: BAUTO,
    route: 'b-automat',
    headingImage: BilePopis,
    pageTitle: "Skupina B na Automat",
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 20 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "18 let"
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "do 3 500kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 34 hodin praxe"
        },
        {
            icon: Shift,
            title: "Omezení",
            body: "Lze řídit pouze vozidla s automatickou převodovkou"
        },
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 18 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: BilePopis,
            alt: ""
        },
    ],
}