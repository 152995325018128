<template>
  <div id="lqd-contents-wrap">

    <!-- Start Banner -->
    <section class="lqd-section banner w-90percent mx-auto py-2percent sm:w-full" id="banner">
      <div class="container-fluid">
        <div class="row items-center">
          <div class="col col-12" data-parallax="true"
               data-parallax-options='{"ease": "linear", "start": "top top" ,  "end": "bottom+=0px top"}'
               data-parallax-to='{"y": "-210px" ,  "rotationX" : -30}'>
            <div
                class="relative flex flex-col items-center bg-center bg-cover rounded-30 box-shadow-bottom-40 module-inner dark-overlay"
                style="background-image: url('../assets/img/bg.webp');">
              <div class="text-center" data-custom-animations="true"
                   data-ca-options='{"animationTarget": ".lqd-split-chars .lqd-chars .split-inner" ,  "duration" : 350, "delay" : 20, "ease": "power2.out" ,  "initValues": {"y": "80px" ,  "rotationZ" : 10, "opacity" : 0} , "animations": {"y": "0px" ,  "rotationZ" : 0, "opacity" : 1}}'>
                <h1 class="ld-fh-element inline-block relative lqd-highlight-custom lqd-highlight-custom-2 lqd-split-chars text-86-h1 mb-0/2em text-white leading-1em"
                    data-inview="true" data-transition-delay="true"
                    data-delay-options='{"elements": ".lqd-highlight-inner" ,  "delayType": "transition"}'
                    data-split-text="true" data-split-options='{"type": "chars, words"}'>
                  <img width="600" class="transition-opacity" src="./../assets/img/logo_white.svg"
                       alt="Autoškola Fišer">
                </h1>
              </div>
              <div class="w-500 text-center max-w-full" data-custom-animations="true"
                   data-ca-options='{"animationTarget": ".lqd-split-lines .lqd-lines .split-inner" ,  "duration" : 500, "startDelay" : 100, "delay" : 100, "ease" : ["power4.out"], "initValues": {"y": "70px" ,  "opacity" : 0} , "animations": {"y": "0px" ,  "opacity" : 1}}'>
                <p class="text-20 leading-1/25em text-white-60 ld-fh-element mb-0/5em inline-block relative lqd-split-lines"
                   data-split-text="true" data-split-options='{"type": "lines"}'> Autoškola, která vás
                  nezklame...</p>
              </div>
              <div class="flex items-center justify-center w-full relative mt-10 mb-10 sm:flex-col module-btn"
                   data-custom-animations="true"
                   data-ca-options='{"animationTarget": "img , .btn" ,  "duration" : 500, "startDelay" : 100, "delay" : 100, "ease" : ["power4.out"], "initValues": {"scaleX" : 0, "scaleY" : 0} , "animations": {"scaleX" : 1, "scaleY" : 1}}'>
                <a href="#groups"
                   class="btn btn-solid btn-hover-txt-switch-change btn-icon-right bg-white text-15 font-medium whitespace-nowrap text-black rounded-100 module-btn-md"
                   data-localscroll="true">
                  <span class="btn-txt">Pokračovat na skupiny</span>
                  <span class="btn-icon">
													<img src="./../assets/img/icons/arrow-right.svg" alt="arrow right">
												</span>
                </a>
              </div>
              <div data-custom-animations="true" class="banner-signup-button"
                   data-ca-options='{"animationTarget": "img , .btn" ,  "duration" : 500, "startDelay" : 100, "delay" : 100, "ease" : ["power4.out"], "initValues": {"scaleX" : 0, "scaleY" : 0} , "animations": {"scaleX" : 1, "scaleY" : 1}}'>
                <a href="assets/files/prihlaska_do_vycviku.pdf" target="_blank"
                   class="btn btn-solid btn-hover-txt-switch-change btn-icon-right text-13 font-medium whitespace-nowrap bg-blue-600 text-white rounded-100 module-btn-sm"
                   data-localscroll="true">
                  <span class="btn-txt">Přihláška do výcviku</span>
                  <span class="btn-icon w-15">
										<img src="./../assets/img/icons/download.svg" alt="Download">
									</span>
                </a>
              </div>
              <div data-custom-animations="true" class="banner-signup-button"
                   data-ca-options='{"animationTarget": "img , .btn" ,  "duration" : 500, "startDelay" : 100, "delay" : 100, "ease" : ["power4.out"], "initValues": {"scaleX" : 0, "scaleY" : 0} , "animations": {"scaleX" : 1, "scaleY" : 1}}'>
                <a href="assets/files/lekarsky_posudek.pdf" target="_blank"
                   class="btn btn-solid btn-hover-txt-switch-change btn-icon-right text-13 font-medium whitespace-nowrap bg-blue-700 text-white rounded-100 module-btn-sm"
                   data-localscroll="true">
                  <span class="btn-txt">Lékařský posudek</span>
                  <span class="btn-icon w-15">
										<img src="./../assets/img/icons/download.svg" alt="Download">
									</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Banner -->

    <!-- Start Groups -->
    <section class="lqd-section business-solutions py-70" id="groups">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center" data-custom-animations="true"
             data-ca-options='{"animationTarget": " .ld-fancy-heading , .lqd-split-lines .lqd-lines .split-inner" ,  "duration" : 1000, "startDelay" : 100, "delay" : 100, "ease": "expo.out" ,  "initValues": {"y": "70px" ,  "opacity" : 0} , "animations": {"y": "0px" ,  "opacity" : 1}}'>
          <div class="ld-fancy-heading relative mb-20 py-5 px-15 bg-slate-100 rounded-100">
            <h6 class="ld-fh-element inline-block relative m-0 text-12 tracking-1 uppercase text-slate-400">
              Skupiny</h6>
          </div>
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Naše služby</h2>
          </div>
          <div class="ld-fancy-heading relative lqd-el-has-inner-anim w-550 sm:w-full">
            <p class="ld-fh-element mb-0/5em inline-block relative" data-split-text="true"
               data-split-options='{"type": "lines"}'> Výcvik probíhá v přátelské atmosféře.</p>
            <p class="ld-fh-element mb-0/5em inline-block relative" data-split-text="true"
               data-split-options='{"type": "lines"}'> Naši lektoři jsou zkušení a trpěliví.</p>
            <p class="ld-fh-element mb-0/5em inline-block relative" data-split-text="true"
               data-split-options='{"type": "lines"}'> Přizpůsobíme se vašim potřebám.</p>
          </div>
          <div class="relative w-full flex" data-custom-animations="true"
               data-ca-options='{"animationTarget": ".animation-element" ,  "duration" : 1000, "startDelay" : 100, "delay" : 75, "ease": "expo.out" ,  "initValues": {"scaleX" : 0, "scaleY" : 0} , "animations": {"scaleX" : 1, "scaleY" : 1}}'>
            <div class="container-fluid pt-35" data-custom-animations="true"
                 data-ca-options='{"addChildTimelines": false,"animationTarget": ".lqd-iconbox-scale", "duration" : 1000,"startDelay" : 100,"delay" : 75,"ease": "expo.out", "initValues": {"scaleX" : 0,"scaleY" : 0} ,"animations": {"scaleX" : 1,"scaleY" : 1}}'>
              <div class="row -mr-10 -ml-10">
                <GroupView :route="group.route" :title="group.title" :subtitle="group.subtitle" :image="group.image"
                              :key="index" v-for="(group, index) in groups"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Groups -->

    <!-- Start Gallery -->
    <section class="lqd-section pt-130 pb-130 bg-transparent transition-all z-1 relative" id="gallery">
      <div class="container">
        <div class="w-full flex flex-col items-center text-center">
          <div class="ld-fancy-heading">
            <h2 class="mb-0/35em ld-fh-element inline-block relative h1">Galerie</h2>
          </div>
          <div class="relative w-full flex">
            <div class="container-fluid pt-35">
              <div class="row -mr-10 -ml-10">
                <GalleryView :images="images"></GalleryView>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Gallery -->

    <!-- Start Testimonials  -->
    <section id="testimonials"
             class="lqd-section testimonials pt-130 pb-130 bg-transparent transition-all z-1 relative"
             style="background-image: linear-gradient(0deg, #FAF9FE 0%, #fff 100%);">
      <div class="container">
        <div class="row">
          <div class="col col-12 col-xl-4 module-col" data-custom-animations="true"
               data-ca-options='{"animationTarget": ".animation-element, h2, p, img", "ease": "power4.out", "initValues": {"y": "45px", "rotationY" : 65, "opacity" : 0, "transformOrigin": "100% 50% 0px"} , "animations": {"y": "0px", "rotationY" : 0, "opacity" : 1, "transformOrigin": "50% 50% 80px"}}'>
            <div class="ld-fancy-heading relative inline-block">
              <h2 class="ld-fh-element relative text-36 leading-1/15em m-0">Co o nás říkají</h2>
            </div>
            <div class="ld-fancy-heading relative inline-block">
              <h2 class="ld-fh-element relative ld-gradient-heading text-36 bg-transparent leading-1/15em mr-0/2em mb-0 ml-0/2em"
                  style="background-image: linear-gradient(0deg, #749FEF 0%, #4452F2 100%);">naši</h2>
            </div>
            <div class="ld-fancy-heading relative inline-block">
              <h2 class="ld-fh-element relative text-36 mb-0 leading-1/15em">zákazníci.</h2>
            </div>
          </div>
          <div class="col col-12 col-xl-8 relative" data-custom-animations="true"
               data-ca-options='{"animationTarget": ".animation-element", "startDelay" : 200, "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'>
            <div class="ml-5percent lg:m-0">
              <div class="carousel-container lqd-carousel-stack animation-element">
                <div class="carousel-items" data-lqd-flickity='{ "watchCSS":  true }'>
                  <RatingView
                      v-for="(rating, index) in ratings"
                      :key="index"
                      :body="rating.body"
                      :name="rating.name"
                      :stars="rating.stars"
                  />
                </div>
                <div class="lqd-carousel-stack-nav">
                  <button class="lqd-carousel-stack-btn lqd-carousel-stack-prev rounded-100">
                    <svg width="6" height="10" viewbox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.863 8.387L4.75 9.5L0 4.75L4.75 0L5.863 1.113L2.229 4.75L5.863 8.387Z"></path>
                    </svg>
                  </button>
                  <button class="lqd-carousel-stack-btn lqd-carousel-stack-next rounded-100">
                    <svg width="6" height="10" viewbox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M-2.43187e-05 8.387L1.11298 9.5L5.86298 4.75L1.11298 0L-2.43187e-05 1.113L3.63398 4.75L-2.43187e-05 8.387Z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Testimonials  -->
  </div>

</template>

<script>
import GroupView from "@/components/Home/Group.vue";
import RatingView from "@/components/Home/RatingView.vue";
import GalleryView from "@/components/Home/GalleryView.vue";

export default {
  name: 'HomeScreen',
  components: {
    GroupView,
    RatingView,
    GalleryView
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    ratings() {
      return this.$store.state.ratings;
    },
    images() {
      return this.$store.state.gallery;
    },
  }
}
</script>
