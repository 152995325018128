<template>
  <LightGallery :settings="{ speed: 500, download: false }">
    <a :href="image.image" class="col" v-for="(image, index) in images" :key="index">
      <img class="px-10 my-5 gallery-image an-image" :alt="image.alt" :src="image.image" />
    </a>
  </LightGallery>
</template>

<script>
import LightGallery from 'lightgallery/vue';
export default {
  name: "GalleryView",
  components: {
    LightGallery
  },
  props: {
    images: {}
  }
}
</script>
<style lang="css" scoped>
@import 'lightgallery/css/lightgallery.css';
.gallery-image {
  height: 150px;
  width: auto;
  border-radius: 30px;
}
</style>
