<template>
  <footer id="site-footer" class="main-footer relative overflow-hidden">
    <section class="lqd-section py-50 lg:pb-0">
      <div class="container">
        <div class="row items-start">
          <div class="col col-12 col-md-6 pr-80 mb-30 sm:pr-0">
            <iframe style="border:none" src="https://frame.mapy.cz/s/mevaponeba" width="400" height="280"
                    frameborder="0"></iframe>
          </div>
          <div class="col col-10 col-md-3 mb-30">
            <div class="ld-fancy-heading relative">
              <h6 class="text-10 uppercase tracking-1 mb-3/5em text-black-50 ld-fh-element inline-block relative">
                Kontaktujte nás</h6>
            </div>

            <div class="ld-fancy-heading relative">
              <h6 class="text-10 uppercase tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">
                Martin Fišer</h6>
            </div>
            <div class="ld-fancy-heading relative">
              <a href="tel:+420603797079"
                 class="text-18 font-medium mb-1/75em text-black ld-fh-element inline-block relative">
                +420 603 797 079</a>
            </div>

            <div class="ld-fancy-heading relative">
              <span class="text-10 font-bold tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">Profesní a referentské školení řidičů, přihlášky do autoškoly: </span>
              <h6 class="text-10 uppercase tracking-1 text-black-60 ld-fh-element inline-block relative">
                Jana Fišerová</h6>
            </div>
            <div class="ld-fancy-heading relative">
              <a href="tel:+420603260907"
                 class="text-18 font-medium mb-1/75em text-black ld-fh-element inline-block relative">
                +420 604 656 862</a>
            </div>

            <div class="ld-fancy-heading relative">
              <h6 class="text-10 uppercase tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">
                František Fišer ml.</h6>
            </div>
            <div class="ld-fancy-heading relative">
              <a href="tel:+420603449813"
                 class="text-18 font-medium mb-1/75em text-black ld-fh-element inline-block relative">
                +420 603 449 813</a>
            </div>

            <div class="ld-fancy-heading relative">
              <h6 class="text-10 uppercase tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">
                František Fišer st.</h6>
            </div>
            <div class="ld-fancy-heading relative">
              <a href="tel:+420603213505"
                 class="text-18 font-medium mb-1/75em text-black ld-fh-element inline-block relative">
                +420 603 213 505</a>
            </div>

            <div class="ld-fancy-heading relative">
              <h6 class="text-10 uppercase tracking-1 text-black-60 ld-fh-element mb-0/5em inline-block relative">
                E-mail</h6>
            </div>
            <div class="ld-fancy-heading relative">
              <a href="mailto:autoskolafiser@email.cz"
                 class="text-18 font-medium mb-1/75em text-black  ld-fh-element inline-block relative">
                autoskolafiser@email.cz</a>
            </div>
          </div>
          <div class="col col-12">
            <div class="pb-10 divider">
              <span class="w-full block border-top border-black-10"></span>
            </div>
          </div>
          <div class="col col-12 col-md-9 pt-20 pb-35 module-text">
            <div class="lqd-imggrp-img-container inline-flex relative items-center justify-center">
              <p>Copyright © <span id="year">{{ new Date().getFullYear().toString() }}</span> - <a
                  href="https://autoskolamelnik.cz">https://autoskolamelnik.cz</a> - Všechna práva vyhrazena.</p>
            </div>
          </div>
          <div class="col col-12 col-md-3 self-center text-end pt-20 pb-35">
            <div class="flex items-cente justify-end gap-25 sm:justify-start">
									<span class="grid-item">
										<a class="inline-flex icon social-icon social-icon-instagram text-30 text-badge"
                       href="https://www.instagram.com/autoskola.fiser/" target="_blank">
											<span class="sr-only">Instagram</span>
											<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="1em"
                           viewBox="0 0 448 512"><path
                          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
										</a>
									</span>
              <span class="grid-item">
										<a class="inline-flex icon social-icon social-icon-twitter text-30 text-badge"
                       href="https://www.facebook.com/groups/586461904844953/" target="_blank">
											<span class="sr-only">Facebook</span>
											<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="currentColor"
                           height="1em" viewBox="0 0 50 50"><path
                          d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path></svg>
										</a>
									</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
}
</script>