import DE from "@/assets/img/icons/groups/DE.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";

import Bus from "@/assets/img/groups/d/bus.webp";

export default {
    title: "Autobus + Přívěs",
    subtitle: "od 21 let",
    image: DE,
    route: 'de',
    pageTitle: "Skupina D+E",
    headingImage: Bus,
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 10 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "21 let (18 let za níže uvedených podmínek)",
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "Přívěs s hmotností nad 750 kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "6 hodin teorie a 12 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: '<b>Řidičské oprávnění pro skupinu D+E můžete získat už v 21 letech, s omezením pouze k řízení vozidel:</b><br>' +
                'a) Ministerstva vnitra používaných policií,<br>' +
                'b) Vězeňské služby České republiky,<br>' +
                'c) ozbrojených sil České republiky,<br>' +
                'd) obecní policie,<br>' +
                'e) Hasičského záchranného sboru České republiky a jednotek požární ochrany,<br>' +
                'f) celních orgánů,<br>' +
                'g) při zkušební jízdě v souvislosti s jejich opravou nebo údržbou<br>' +
                '<br>' +
                'Po dosažení věku 24 let toto omezení pozbývá platnosti.<br>'
        },
        {
            icon: ID,
            text: '<b>Řidičské oprávnění pro skupiny D+E lze udělit rovněž osobě, která dosáhla věku 21 let nebo 23 let. Toto řidičské oprávnění opravňuje do dosažení věku 24 let jeho držitele k řízení:</b><br>' +
                'a) za účelem řízení v rámci vstupního školení na profesní osvědčení (profesní způsobilost),<br>' +
                'b) pokud je držitelem profesního osvědčení (dnes profesní způsobilost) a podrobil se vstupnímu školení: <br>' +
                '- v rozšířeném rozsahu 280 hodin (od 21 let), nebo<br>' +
                '- v základním rozsahu 140 hodin (od 23 let), nebo řídí-li vozidlo linkové osobní dopravy a trasa linky nepřesahuje 50 km (od 21 let).'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Document,
            text: 'odborná způsobilost'
        },
        {
            icon: Doctor,
            text: 'psychologické vyšetření'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Bus,
            alt: ""
        }
    ],
}