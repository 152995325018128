import T from "@/assets/img/icons/groups/T.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import B from "@/assets/img/icons/groups/B.svg";
import C from "@/assets/img/icons/groups/C.svg";
import D from "@/assets/img/icons/groups/D.svg";

export default {
    title: "Traktor",
    subtitle: "od 17 let",
    image: T,
    route: 't',
    pageTitle: "Skupina T",
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 18 900 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "od 17 let"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "13 hodin teorie a 33 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 17 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    extensions: [
        {
            fromGroup: B,
            length: "9 hodin teorie a 17 hodin praxe",
            age: "17 let",
            price: "12 900 Kč"
        },
        {
            fromGroup: C,
            length: "9 hodin teorie a 17 hodin praxe",
            age: "17 let",
            price: "12 900 Kč"
        },
        {
            fromGroup: D,
            length: "9 hodin teorie a 17 hodin praxe",
            age: "17 let",
            price: "12 900 Kč"
        },
    ]
    // gallery: [
    // ],
}