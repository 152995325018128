import { createRouter, createWebHistory } from 'vue-router'

import { routes } from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    addScript('themejs', '/assets/js/theme.js');

    if (to.name !== 'Group') {
        document.title = to.meta.title + ' | Autoškola Fišer';
    }

    next();
});

function addScript(scriptId, scriptSrc) {
    if (!document.getElementById(scriptId)) {
        let script = document.createElement('script');
        script.id = scriptId;
        script.src = scriptSrc;
        document.body.appendChild(script);
    }
}

export default router
