import B from "@/assets/img/icons/groups/B.svg";
import Price from "@/assets/img/icons/price.svg";
import ID from "@/assets/img/icons/id.svg";
import Clock from "@/assets/img/icons/clock.svg";
import Weight from "@/assets/img/icons/weight.svg";
import Document from "@/assets/img/icons/document.svg";
import Doctor from "@/assets/img/icons/doctor.svg";
import Location from "@/assets/img/icons/location.svg";
import Cross from "@/assets/img/icons/cross.svg";
import Fanda from "@/assets/img/groups/b/fanda.webp";
import Bile from "@/assets/img/groups/b/bile.webp";
import BilePopis from "@/assets/img/groups/b/bile_popis.webp";
import Cervene from "@/assets/img/groups/b/cervene.webp";
import Hnede from "@/assets/img/groups/b/hnede.webp";


export default {
    title: "Osobní Auto",
    subtitle: "od 18 let",
    image: B,
    headingImage: Fanda,
    route: 'b',
    pageTitle: "Skupina B",
    heading: [
        {
            icon: Price,
            title: "Cena",
            body: "od 18 000 Kč"
        },
        {
            icon: ID,
            title: "Věk",
            body: "18 let"
        },
        {
            icon: Weight,
            title: "Hmotnost",
            body: "do 3 500kg"
        },
        {
            icon: Clock,
            title: "Rozsah výcviku",
            body: "11 hodin teorie a 34 hodin praxe"
        }
    ],
    conditions: [
        {
            icon: ID,
            text: 'věk: 18 let'
        },
        {
            icon: Document,
            text: 'vyplněná a podepsaná Přihláška do výcviku'
        },
        {
            icon: Doctor,
            text: 'platný lékařský posudek potvrzený praktickým lékařem'
        },
        {
            icon: Location,
            text: 'trvalý nebo přechodný pobyt v ČR'
        },
        {
            icon: Cross,
            text: 'nebýt ve výkonu sankce nebo trestu zákazu řízení'
        },
    ],
    gallery: [
        {
            image: Fanda,
            alt: ""
        },
        {
            image: Bile,
            alt: ""
        },
        {
            image: BilePopis,
            alt: ""
        },
        {
            image: Cervene,
            alt: ""
        },
        {
            image: Hnede,
            alt: ""
        },
    ],
}