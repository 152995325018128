import Training from "@/assets/img/icons/groups/Training.svg";

export default {
    title: "Školení řidičů",
    subtitle: "Akreditované školící středisko",
    image: Training,
    route: 'skoleni-ridicu',
    pageTitle: "Profesní školení řidičů",
    training: [
        {
            title: "Vstupní školení na profesní průkaz řidiče",
            subtitle: "PROFESNÍ PRŮKAZ ŘIDIČE",
            text: "Vstupní školení na profesní průkaz má standardní rozsah 140 hodin. Týká se řidičů skupin C1, C, C1+E, C+E od 21 let a řidičů skupin D1, D1+E, D, D+E až od 23 let. Profesní školení řidičů, které můžete absolvovat prezenčně je prováděno formou výuky a výcviku a zakončuje se zkouškou z profesní způsobilosti řidiče, která Vás opravňuje k výkonu profese, a to na 5 let.",
            length: "140 hodin",
            price: "od 29 000 Kč"
        },
        {
            title: "Vstupní školení na profesní průkaz řidiče - rozšířené",
            subtitle: "PROFESNÍ PRŮKAZ ŘIDIČE",
            text: "Vstupní školení na profesní průkaz má v rozšířeném pojetí rozsah 280 hodin. Dovoluje řídit vozidla všech skupin C od 18 let a skupin D od 21 let. Profesní školení řidičů je v rozšířené, stejně jako ve standardní, verzi prováděno formou výuky a výcviku a zakončuje se zkouškou z profesní způsobilosti řidiče, bez níž není možné vykonávat práci profesního řidiče.",
            length: "280 hodin",
            price: "od 42 000 Kč"
        },
        {
            title: "Rozšiřující školení na profesní průkaz řidiče",
            text: "Rozšířené školení na profesní průkaz řidiče je určeno pro ty, kteří už profesní průkaz mají a pouze si potřebují rozšířit své znalosti. Rozsah rozšiřujícího profesního školení řidičů je 45 h a volně navazuje na učební osnovy školení vstupního dle § 47 zákona, které jsou doplněny o aktuální témata.",
            subtitle: "PROFESNÍ PRŮKAZ ŘIDIČE",
            length: "45 hodin",
            price: "od 15 000 Kč"
        },
        {
            title: "Obnovovací školení na profesní průkaz řidiče",
            text: "Vlastníte-li profesní průkaz řidiče, jste povinni účastnit se pravidelného profesního školení řidičů, jehož celkový rozsah je 35 hodin a to po dobu 5 let od data vystavení Vašeho profesního průkazu řidiče. Pravidelné školení na profesní průkaz řidiče má rozsah 7 hodin ročně.",
            subtitle: "PROFESNÍ PRŮKAZ ŘIDIČE",
            length: "35 hodin",
            price: "od 7 500 Kč"
        },
        {
            title: "Pravidelné školení na profesní průkaz řidiče",
            text: "Vlastníte-li profesní průkaz řidiče, jste povinni účastnit se pravidelného profesního školení řidičů, jehož celkový rozsah je 35 hodin a to po dobu 5 let od data vystavení Vašeho profesního průkazu řidiče. Pravidelné školení na profesní průkaz řidiče má rozsah 7 hodin ročně.",
            subtitle: "ŠKOLENÍ ŘIDIČŮ REFERENTŮ",
            length: "7 hodin",
            price: "od 1 500 Kč"
        }
    ]
}